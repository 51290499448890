

import React,{Component} from 'react';
import axios from 'axios';
import { Link, useNavigate} from 'react-router-dom';
import UserIcon from "../Utilities/imgs/user1.png"
import '../Utilities/css/main.css';
import '../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../Utilities/assets/css/style.css';
// import '../Utilities/assets/vendor/bootstrap-icons/bootstrap-icons.css';
import Header from './Navigation/Header';
// import { APIResearchersList, APIResearchGet } from '../Utilities/APIS';

// export default class ViewResearch extends React.Component {
class Dashboard extends Component {
constructor(props){
    
    super(props);
    this.state = {
        CompletedData:[],
        PublishedData:[],
        WorkingProgressData:[],
        ResearchersData:[],
    }    
}

componentDidMount(){
    // this.loadData();
}

// loadData = () =>{

//     axios.get(APIResearchGet)
//     .then(res => {
//         let results =JSON.stringify(res.data);
//         let UserResearch =JSON.parse(results); 

//         // filter Progress 
//         let progress = "Published";
//         let completed = "Completed";
//         let workingProgress = "Working Progress";

//         let filteredPublishedData = UserResearch.filter(item => item.researchStatus === progress);
//         let filteredCompletedData = UserResearch.filter(item => item.researchStatus === completed);
//         let filteredWorkingPublishedData = UserResearch.filter(item => item.researchStatus === workingProgress);
//         this.setState({PublishedData:filteredPublishedData})
//         this.setState({CompletedData:filteredCompletedData})
//         this.setState({WorkingProgressData:filteredWorkingPublishedData})
//         })
//     .catch(err=>{console.log(err);})

//     axios.get(APIResearchersList)
//     .then(res => {
//             let results =JSON.stringify(res.data);
//             let jsonResults =JSON.parse(results); 
//             this.setState({ResearchersData:jsonResults})
//         })
//     .catch(err=>{console.log(err);})
// }

goToViewMore = (Data,researchStatus) =>{
    // console.log("this.state.ResearchViews"+JSON.stringify(this.state.ResearchViews))
    let viewerProps = {
        ViewData:Data,
        researchStatus:researchStatus
    };

    this.props.navigate("/viewMore",{ state: viewerProps });
}
goToResearchers = () =>{this.props.navigate("/researchers");}     
render() {
const { CompletedData,PublishedData,WorkingProgressData,ResearchersData} =this.state;
return (
        <div className='body-container'>    
            <div className='nav-header-container'>
                <Header/>
            </div>
            <div className='inner-body-container'>
                <div className="alert alert-success bg-success text-light border-0 alert-dismissible" role="alert">
                    <strong>Dashboard</strong> 
                </div>
                <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                            <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Safari Booking</div>
                                            <div className="h5 mb-0 font-weight-bold text-warning text-gray-800">12</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-warning"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Package Booking</div>
                                            <div className="h6 mb-0 font-weight-bold text-success text-gray-800">10</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-success"></i>
                                        </div> 
                                    </div>
                                </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4 ">
                            <div className="card border-left-primary shadow h-100 py-2">
                            <span   >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Safari Reservation</div>
                                            <div className="h6 mb-0 font-weight-bold text-primary">2</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-primary"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                            <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Package Reservation</div>
                                            <div className="h6 mb-0 mr-3 font-weight-bold  text-info  text-gray-800">2</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-eye-fill text-info"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>
                </div>

                <div className="row">
                    <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-warning shadow h-100 py-2">
                            <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">Contact Us</div>
                                            <div className="h5 mb-0 font-weight-bold text-warning text-gray-800">12</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-warning"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-success shadow h-100 py-2">
                                <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Package Booking</div>
                                            <div className="h6 mb-0 font-weight-bold text-success text-gray-800">10</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-success"></i>
                                        </div> 
                                    </div>
                                </div>
                                </span>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 mb-4 ">
                            <div className="card border-left-primary shadow h-100 py-2">
                            <span   >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Safari Reservation</div>
                                            <div className="h6 mb-0 font-weight-bold text-primary">2</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-brush-fill text-primary"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>

                        <div className="col-xl-3 col-md-6 mb-4">
                            <div className="card border-left-info shadow h-100 py-2">
                            <span  >
                                <div className="card-body">
                                    <div className="row no-gutters align-items-center">
                                        <div className="col mr-2">
                                            <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Package Reservation</div>
                                            <div className="h6 mb-0 mr-3 font-weight-bold  text-info  text-gray-800">2</div>
                                        </div>
                                        <div className="col-auto">
                                            <i className="bi bi-eye-fill text-info"></i>
                                        </div> 
                                    </div>
                                </div>
                            </span>
                            </div>
                        </div>
                </div>

            </div>
        </div>
);
}}
function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        return <Component {...props} navigate={navigate} />;
    };
}


export default withNavigation(Dashboard);
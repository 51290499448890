import React,{useState,useEffect} from 'react';
import {Link,useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { clearLocalStorageData } from '../../Utilities/Functions';
import IsUserAuthenticated from './IsUserAuthenticated';
import User2Icon from "../../Utilities/imgs/user2.png"
import { OthersNavigationLinks, PackagesNavigationLinks, SafariNavigationLinks, TransportNavigationLinks } from '../../Utilities/Data';

function Header() {
    const navigate = useNavigate();
    const [userName, setUserName] = useState('');
    // const [userProfileImage, setUserProfileImage] = useState(null);



    useEffect(() => {
        const user = localStorage.getItem('UserDetails');
        if (user) {
            const jsonData = JSON.parse(user)
            let userName = jsonData.name;
            // let profileImage = jsonData.profileImage;
            setUserName(userName);
            // setUserProfileImage(profileImage);

        }
    }, []);

    



    const logout = () => {
        clearLocalStorageData();
        navigate('/');
    };
    

    return (
        <>
            <IsUserAuthenticated/>
            {/* <Nav variant="pills" activeKey="1" onSelect={handleSelect}> */}
            <Nav variant="pills" activeKey="1" >
                <Nav.Item>
                    <Nav.Link eventKey="1"  as={Link} to="/dashboard">
                        Dashboard
                    </Nav.Link>
                </Nav.Item> &nbsp;&nbsp;
                

                <NavDropdown title="Packages" id="nav-dropdown">
                    <NavDropdown.Divider />
                    {PackagesNavigationLinks.map((item,index)=>(
                        <div key={index}>
                            <NavDropdown.Item eventKey="4.1">
                                <Link as={Link} to={item.path}> {item.name}</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </div>
                    ))}
                </NavDropdown>

                <NavDropdown title="Safaris" id="nav-dropdown">
                <NavDropdown.Divider />
                    {SafariNavigationLinks.map((item,index)=>(
                        <div key={index}>
                            <NavDropdown.Item eventKey="4.1">
                                <Link as={Link} to={item.path}> {item.name}</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </div>
                    ))}
                </NavDropdown>

                <NavDropdown title="Transport" id="nav-dropdown">
                <NavDropdown.Divider />
                    {TransportNavigationLinks.map((item,index)=>(
                        <div key={index}>
                            <NavDropdown.Item eventKey="4.1">
                                <Link as={Link} to={item.path}> {item.name}</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </div>
                    ))}
                </NavDropdown>

                <NavDropdown title="Others" id="nav-dropdown">
                <NavDropdown.Divider />
                    {OthersNavigationLinks.map((item,index)=>(
                        <div key={index}>
                            <NavDropdown.Item eventKey="4.1">
                                <Link as={Link} to={item.path}> {item.name}</Link>
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                        </div>
                    ))}
                </NavDropdown>

            </Nav>
            <div className='user-profile-div'>
                <div className='my-grid-container-2-columns'>
                    <div className='my-grid-item-user'>
                        <img src={User2Icon} className="user-icon" alt='user-icon' />
                    </div>
                    <div className='my-grid-item-user'>
                    <NavDropdown title={userName} id="nav-dropdown">
                        <NavDropdown.Item eventKey="4.2" as={Link} to="/profile" >Profile</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.1" onClick={logout} >Log Out </NavDropdown.Item>
                    </NavDropdown>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Header;


import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import {APIPackageCreate, APIPackageDelete, APIPackageFileName, APIPackageList, APIPackageUpdateImage, APIPackageUpdateText,AUTH_TOKEN, } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { DestinationNamesList, TourDaysList, TourNamesList } from '../../Utilities/Data';
import { getCodeFullName } from '../../Utilities/Functions';

const headers = {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${AUTH_TOKEN}`};
export default class Package extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        
        ShowPostButton:true,
        showAlert:false,
        PackagesData:[],

        PackageTitleName:'',
        PackageDays:'',
        PackageCountry:'',
        PackageTourName:'',
        ActivityHighlightsInputs:[{data:''}],
        WhyBookThisTripInputs:[{data:''}],
        PackageImage:null,
        SelectedImage:null,

        SuccessPostMsg:'',
        ErrorPostMsg:'',

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowEditImageModel:false,
        ShowDeleteModel:false,

        // Show
        ItemShowPackageTitleName:'',
        ItemShowPackageDays:'',
        ItemShowPackageCountry:'',
        ItemShowPackageTourName:'',
        ItemShowActivityHighlights:[],
        ItemShowWhyBookThisTrip:[],

        // Edit
        ItemEditPackageTitleName:'',
        ItemEditPackageDays:'',
        ItemEditPackageCountry:'',
        ItemEditPackageTourName:'',
        ItemEditActivityHighlights:[],
        ItemEditWhyBookThisTrip:[],

        ItemShowImage:'',
        updateId:'',
        deleteId:'',
        deleteFileName:'',
        fileNameToDelete:'',
        ItemShowDescription:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIPackageList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({PackagesData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false}); this.resetModelShowAlerts();}
handleShowDetailsModel = (packageTitleName,days,country,tourName,activityHighlights,whyBookThisTrip,image) => {
    this.setState({ItemShowPackageTitleName:packageTitleName});
    this.setState({ItemShowPackageDays:days});
    this.setState({ItemShowPackageCountry:country});
    this.setState({ItemShowPackageTourName:tourName});
    this.setState({ItemShowActivityHighlights:JSON.parse(activityHighlights)});
    this.setState({ItemShowWhyBookThisTrip:JSON.parse(whyBookThisTrip)});
    this.setState({ItemShowImage:APIPackageFileName+image});
    this.setState({ShowDetailsModel:true});
}

handleCloseEditTextModel = () => {this.setState({ShowEditTextModel:false});this.resetModelShowAlerts();}
handleShowEditTextModel = (packageTitleName,days,country,tourName,activityHighlights,whyBookThisTrip,updateId) => {
    this.setState({ItemEditPackageTitleName:packageTitleName});
    this.setState({ItemEditPackageDays:days});
    this.setState({ItemEditPackageCountry:country});
    this.setState({ItemEditPackageTourName:tourName});
    this.setState({ItemEditActivityHighlights:JSON.parse(activityHighlights)});
    this.setState({ItemEditActivityHighlights:JSON.parse(activityHighlights)});
    this.setState({ItemEditWhyBookThisTrip:JSON.parse(whyBookThisTrip)});
    this.setState({updateId:updateId})
    this.setState({ShowEditTextModel:true});
    }
handleCloseEditImageModel = () => {this.setState({ShowEditImageModel:false});this.resetModelShowAlerts();}
handleShowEditImageModel = (updateId,image) => {
        this.setState({PackageImage:image});
        this.setState({updateId:updateId})
        this.setState({fileNameToDelete:image});
        this.setState({ItemShowImage:APIPackageFileName+image});
        this.setState({ShowEditImageModel:true});
        }
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (packageTitleName,deleteId,deleteFileName) => {
    this.setState({ItemShowPackageTitleName:packageTitleName});
    this.setState({deleteId:deleteId}); 
    this.setState({deleteFileName:deleteFileName}); 
    this.setState({ItemShowImage:APIPackageFileName+deleteFileName});
    this.setState({ShowDeleteModel:true});
}

setPackageTitleName = (text) =>{this.setState({PackageTitleName:text.target.value})}
setPackageDays = (text) =>{this.setState({PackageDays:text.target.value})}
setPackageTourName = (text) =>{this.setState({PackageTourName:text.target.value})}
setPackageCountry = (text) =>{this.setState({PackageCountry:text.target.value})}
setActivityHighlightsTextInputs = (InputsArray) =>{this.setState({ActivityHighlightsInputs:InputsArray})}
setWhyBookThisTripTextInputs = (InputsArray) =>{this.setState({WhyBookThisTripInputs:InputsArray})}

setItemEditPackageTitleName  = (text) =>{this.setState({ItemEditPackageTitleName:text.target.value})}
setItemEditPackageDays  = (text) =>{this.setState({ItemEditPackageDays:text.target.value})}
setItemEditPackageCountry  = (text) =>{this.setState({ItemEditPackageCountry:text.target.value})}
setItemEditPackageTourName  = (text) =>{this.setState({ItemEditPackageTourName:text.target.value})}


handleAdd = (currentInputs,inputsToSet) =>{
    // console.log("Adding..");
    const newInputs = [...currentInputs];
    newInputs.push('');

    if(inputsToSet === 'ActivityHighlights'){this.setActivityHighlightsTextInputs(newInputs)}
    else if(inputsToSet === 'WhyBookThisTrip'){this.setWhyBookThisTripTextInputs(newInputs)}
    else if(inputsToSet === 'DetailedItinerary'){this.setDetailedItineraryTextInputs(newInputs)}
}
handleDelete = (currentInputs,inputsToSet) =>{
    // console.log("Deleting..");
    const newInputs = [...currentInputs];
    newInputs.pop();
    if(inputsToSet === 'ActivityHighlights'){this.setActivityHighlightsTextInputs(newInputs)}
    else if(inputsToSet === 'WhyBookThisTrip'){this.setWhyBookThisTripTextInputs(newInputs)}
    else if(inputsToSet === 'DetailedItinerary'){this.setDetailedItineraryTextInputs(newInputs)}
}
handleTextInputChange = (text,index,currentInputs,inputsToSet) =>{
    // console.log("Handling Changes..");
    // console.log(".."+JSON.stringify(text));
    const newInputs = [...currentInputs];
    newInputs[index]={data:text.target.value};
    if(inputsToSet === 'ActivityHighlights'){this.setActivityHighlightsTextInputs(newInputs)}
    else if(inputsToSet === 'WhyBookThisTrip'){this.setWhyBookThisTripTextInputs(newInputs)}
    else if(inputsToSet === 'DetailedItinerary'){this.setDetailedItineraryTextInputs(newInputs)}
}


handleImageUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) 
        {
            this.setState({PackageImage:file})
            const reader = new FileReader();
            
            reader.onloadend = () => {
            this.setState({SelectedImage:reader.result}); // Set the base64 image data to state
            };
            reader.readAsDataURL(file); // Convert image to base64 string
        }
};

resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
    this.setState({ShowPostButton:true});
    this.setState({ItemShowImage:null});

}
postPackage =  async () => 
    {
        let packageTitleName = this.state.PackageTitleName;
        let days = this.state.PackageDays;
        let country = this.state.PackageCountry;
        let tourName = this.state.PackageTourName;
        let file = this.state.PackageImage;
        let activityHighlights = JSON.stringify(this.state.ActivityHighlightsInputs);
        let whyBookThisTrip = JSON.stringify(this.state.WhyBookThisTripInputs);



        if (packageTitleName.length === 0 || days.length === 0|| country.length === 0 || tourName.length === 0  || file.length === 0  )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('days', days);
            formData.append('country', country);
            formData.append('tourName', tourName);
            formData.append('packageTitleName', packageTitleName);
            formData.append('activityHighlights', activityHighlights);
            formData.append('whyBookThisTrip', whyBookThisTrip);
        
            try {
                    const response = await axios.post(APIPackageCreate, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadData();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
updatePackageItemText =  async () => {
    let updateId = this.state.updateId;
    let packageTitleName = this.state.ItemEditPackageTitleName;
    let tourName = this.state.ItemEditPackageTourName;
    let days = this.state.ItemEditPackageDays;
    let country = this.state.ItemEditPackageCountry;
    let activityHighlights = JSON.stringify(this.state.ItemEditActivityHighlights);
    let whyBookThisTrip = JSON.stringify(this.state.ItemEditWhyBookThisTrip);


    if (packageTitleName.length === 0 || days.length === 0|| country.length === 0 || tourName.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('updateId', updateId);
        formData.append('days', days);
        formData.append('country', country);
        formData.append('tourName', tourName);
        formData.append('packageTitleName', packageTitleName);
        formData.append('activityHighlights', activityHighlights);
        formData.append('whyBookThisTrip', whyBookThisTrip);
    
        try {
                const response = await axios.put(APIPackageUpdateText, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                this.loadData();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
updatePackageItemImage =  async () => 
    {
        let file = this.state.PackageImage;
        let updateId = this.state.updateId;
        let fileNameToDelete = this.state.fileNameToDelete;

        if (file.length === 0  )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('updateId', updateId);
            formData.append('fileNameToDelete',fileNameToDelete)
        
            try {
                    const response = await axios.put(APIPackageUpdateImage, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadData();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }

deletePackageItem =  async () => 
    {
        let deleteId = this.state.deleteId
        try {
                const response = await axios.delete(APIPackageDelete+deleteId, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.loadData();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
    }
render() {
    const {show,ShowDetailsModel,ShowDeleteModel,ShowEditTextModel,ShowEditImageModel,showAlert,PackagesData,SelectedImage,
            ActivityHighlightsInputs,WhyBookThisTripInputs
    } =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Package Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New Package
                                    </Button>
                                </ButtonGroup>
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    <p>{this.state.AllInputsRequiredError}</p>
                                    <p>{this.state.AxiosErrorMessage}</p>
                                    <p>{this.state.AxiosErrorResponse}</p>
                                </div>
                            </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Package Name</th>
                                            <th className="tr-td-labels text-center">Details</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {PackagesData && PackagesData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.packageTitleName}</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.packageTitleName,item.days,item.country,item.tourName,item.activityHighlights,item.whyBookThisTrip,item.fileName)}>
                                                    Show Item
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditTextModel(item.packageTitleName,item.days,item.country,item.tourName,item.activityHighlights,item.whyBookThisTrip,item.id)}>
                                                    Edit Text
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditImageModel(item.id,item.fileName)}>
                                                    Edit Image
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="danger" onClick={()=>this.handleShowDeleteModel(item.packageTitleName,item.id,item.fileName)}>
                                                    Delete Item
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>


                            <Modal size='lg'  show={show} onHide={()=>this.handleClose()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adding An New Package</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Image</p>
                                                <input type="file" accept="image/*" className="form-control form-inputs" onChange={e=>this.handleImageUpload(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <center>
                                        {SelectedImage && (
                                            <div>
                                                <br></br><br></br>
                                                <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                            </div>
                                        )}
                                    </center>
                                    <br></br>
                                    <div className='my-grid-container-3-columns' >
                                        <div className='my-grid-item'>
                                            <label className="primary-text"><b>Country *</b></label>
                                            <select  className="form-control form-inputs"  onChange={(text)=>this.setPackageCountry (text)}>
                                                <option>Select</option>
                                                {DestinationNamesList.map((item,index)=>(
                                                    <option key={index} value={item.code} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='my-grid-item'>
                                            <label className="primary-text"><b>Tour Name *</b></label>
                                            <select className="form-control form-inputs"  onChange={(text)=>this.setPackageTourName(text)}>
                                                <option>Select</option>
                                                {TourNamesList.map((item,index)=>(
                                                    <option key={index} value={item.code} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='my-grid-item'>
                                            <label className="primary-text"><b>Package Days *</b></label>
                                            <select className="form-control form-inputs"  onChange={(text)=>this.setPackageDays(text)}>
                                                <option>Select</option>
                                                {TourDaysList.map((item,index)=>(
                                                    <option key={index} value={item.code} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div>
                                        <label className="primary-text" ><b>Package Title Name</b></label>
                                        <input type="text" onChange={text=>this.setPackageTitleName (text)} className="form-control form-inputs" autoComplete="off" placeholder="Package Name"/>
                                    </div>
                                    <br></br>
                                    <label className="primary-text"><b>Activity Highlights *</b></label>
                                        {ActivityHighlightsInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                value={item.data}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.ActivityHighlightsInputs,'ActivityHighlights')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.ActivityHighlightsInputs,'ActivityHighlights')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.ActivityHighlightsInputs,'ActivityHighlights')}>
                                                Remove Input
                                            </button>
                                        </center>
                                        <br/><br/>

                                        <label className="primary-text"><b>Why Book This Trip *</b></label>
                                        {WhyBookThisTripInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.WhyBookThisTripInputs,'WhyBookThisTrip')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.WhyBookThisTripInputs,'WhyBookThisTrip')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.WhyBookThisTripInputs,'WhyBookThisTrip')}>
                                                Remove Input
                                            </button>
                                        </center>
                                        <br></br>


                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.postPackage()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>

                            {/* Details Model   */}
                            <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Package Item Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <center>
                                        <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                    </center>
                                    <div>
                                        <p className="primary-text" >
                                            <b>Package Title Name</b><br></br>
                                            {this.state.ItemShowPackageTitleName}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="primary-text" >
                                            <b>Package Country</b><br></br>
                                            {getCodeFullName(this.state.ItemShowPackageCountry,DestinationNamesList)}
                                        </p>
                                    </div>
                                    <div>
                                        <p className="primary-text" >
                                            <b>Package Days</b><br></br>
                                            {getCodeFullName(this.state.ItemShowPackageDays,TourDaysList)}
                                        </p>
                                    </div>

                                    <div>
                                        <p className="primary-text" >
                                            <b>Package Tour </b><br></br>
                                            {getCodeFullName(this.state.ItemShowPackageTourName,TourNamesList)}
                                        </p>
                                    </div>

                                    <div>
                                        <label className="primary-text"><b>Activity Highlights</b></label>
                                        {this.state.ItemShowActivityHighlights && this.state.ItemShowActivityHighlights.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p >{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                    </div>

                                    <div>
                                        <label className="primary-text"><b>Why Book This Trip ?</b></label>
                                        {this.state.ItemShowWhyBookThisTrip && this.state.ItemShowWhyBookThisTrip.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p >{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                             {/* Edit Text Model   */}
                            <Modal size='lg' show={ShowEditTextModel} onHide={()=>this.handleCloseEditTextModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Package Item</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <label className="primary-text" ><b>Package Title Name</b></label>
                                    <input type="text" defaultValue={this.state.ItemEditPackageTitleName}  onChange={text=>this.setItemEditPackageTitleName (text)} className="form-control form-inputs" autoComplete="off" placeholder="Package Name"/>
                                    <br></br>
                                    <div className='my-grid-container-3-columns' >
                                        <div className='my-grid-item'>
                                            <label className="primary-text"><b>Country *</b></label>
                                            <select  className="form-control form-inputs"  onChange={(text)=>this.setItemEditPackageCountry (text)}>
                                                <option value={this.state.ItemEditPackageCountry} >{getCodeFullName(this.state.ItemEditPackageCountry,DestinationNamesList)}</option>
                                                {DestinationNamesList.map((item,index)=>(
                                                    <option key={index} value={item.code} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='my-grid-item'>
                                            <label className="primary-text"><b>Tour Name *</b></label>
                                            <select className="form-control form-inputs"  onChange={(text)=>this.setItemEditPackageTourName(text)}>
                                                <option value={this.state.ItemEditPackageTourName} >{getCodeFullName(this.state.ItemEditPackageTourName,TourNamesList)}</option>
                                                {TourNamesList.map((item,index)=>(
                                                    <option key={index} value={item.code} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='my-grid-item'>
                                            <label className="primary-text"><b>Package Days *</b></label>
                                            <select className="form-control form-inputs"  onChange={(text)=>this.setItemEditPackageDays(text)}>
                                                <option value={this.state.ItemEditPackageDays} >{getCodeFullName(this.state.ItemEditPackageDays,TourDaysList)}</option>
                                                {TourDaysList.map((item,index)=>(
                                                    <option key={index} value={item.code} >{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <label className="primary-text"><b>Activity Highlights *</b></label>
                                        {this.state.ItemEditActivityHighlights.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                defaultValue={item.data}
                                                onChange={(data) => {
                                                    const clonedItems = [...this.state.ItemEditActivityHighlights]; // Shallow copy
                                                    clonedItems[index].data = data.target.value;
                                                    this.setState({ItemEditActivityHighlights: clonedItems });
                                                }}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br/><br/>

                                        <label className="primary-text"><b>Why Book This Trip *</b></label>
                                        {this.state.ItemEditWhyBookThisTrip.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                defaultValue={item.data}
                                                onChange={(data) => {
                                                    const clonedItems = [...this.state.ItemEditWhyBookThisTrip]; // Shallow copy
                                                    clonedItems[index].data = data.target.value;
                                                    this.setState({ ItemEditWhyBookThisTrip: clonedItems });
                                                }}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br></br>

                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                            </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                        </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditTextModel()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.updatePackageItemText()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                             {/* Edit Image Model   */}
                            <Modal show={ShowEditImageModel} onHide={()=>this.handleCloseEditImageModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Package Item Image</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <div className='my-grid-container-2-columns' >
                                            <div className='my-grid-item'>
                                                <div>
                                                    
                                                    <p className="primary-text" >Image</p>
                                                    <input type="file" accept="image/*" className="form-control form-inputs" onChange={e=>this.handleImageUpload(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <center>
                                            <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                            
                                            {SelectedImage && (
                                                <div>
                                                    <br></br><br></br>
                                                    <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                </div>
                                            )}
                                        </center>
                                        <br></br>
                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                            </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                        </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditImageModel()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.updatePackageItemImage()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>

                            {/* Delete Model   */}
                            <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Menu Item</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Delete This Package Item ??</p>
                                    </div>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowPackageTitleName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                        </div>

                                    </div>
                                    <center>
                                        <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                    </center>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.deletePackageItem()}>
                                        Yes Delete Item
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}



import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import {APIBlogCreate, APIBlogDelete, APIBlogFileName, APIBlogList, APIBlogUpdateImage, APIBlogUpdateText,AUTH_TOKEN, } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { getCodeFullName } from '../../Utilities/Functions';
import { TourNamesList } from '../../Utilities/Data';

const headers = {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${AUTH_TOKEN}`};
export default class Blogs extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        ShowPostButton:true,
        showAlert:false,
        BlogData:[],

        SafariName:'',
        ShortDescription:'',
        LongDescriptionInputs:[{data:''}],
        SafariImage:null,
        SelectedImage:null,

        // Posting
        SuccessPostMsg:'',
        ErrorPostMsg:'',
        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowEditImageModel:false,
        ShowDeleteModel:false,

        // Show
        ItemShowBlogName:'',
        ItemShowShortDescription:'',
        ItemShowLongDescription:[],

        // Edit
        ItemEditSafariName:'',
        ItemEditShortDescription:'',
        ItemEditLongDescription:[],

        ItemShowImage:'',
        updateId:'',
        deleteId:'',
        deleteFileName:'',
        ItemShowDescription:'',
        FileNameToDelete:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIBlogList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({BlogData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false}); this.resetModelShowAlerts();}
handleShowDetailsModel = (safariName,shortDescription,longDescription,image) => {
    this.setState({ItemShowBlogName:safariName});
    this.setState({ItemShowShortDescription:shortDescription});
    this.setState({ItemShowLongDescription:JSON.parse(longDescription)});
    this.setState({ItemShowImage:APIBlogFileName+image});
    this.setState({ShowDetailsModel:true});
}

handleCloseEditTextModel = () => {this.setState({ShowEditTextModel:false});this.resetModelShowAlerts();}
handleShowEditTextModel = (safariName,shortDescription,longDescription,updateId) => {
    this.setState({ItemEditSafariName:safariName});
    this.setState({ItemEditShortDescription:shortDescription});
    this.setState({ItemEditShortDescription:shortDescription});
    this.setState({ItemEditLongDescription:JSON.parse(longDescription)});
    this.setState({updateId:updateId})
    this.setState({ShowEditTextModel:true});
    }
handleCloseEditImageModel = () => {this.setState({ShowEditImageModel:false});this.resetModelShowAlerts();}
handleShowEditImageModel = (updateId,image) => {
        this.setState({SafariImage:image});
        this.setState({FileNameToDelete:image})
        this.setState({updateId:updateId})
        this.setState({ItemShowImage:APIBlogFileName+image});
        this.setState({ShowEditImageModel:true});
        }
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (SafariName,deleteId,deleteFileName) => {
    this.setState({ItemShowBlogName:SafariName});
    this.setState({deleteId:deleteId}); 
    this.setState({deleteFileName:deleteFileName}); 
    this.setState({ItemShowImage:APIBlogFileName+deleteFileName});
    this.setState({ShowDeleteModel:true});
}

setSafariName = (text) =>{this.setState({SafariName:text.target.value})}
setShortDescriptionTextInputs = (text) =>{this.setState({ShortDescription:text.target.value})}
setLongDescriptionTextInputs = (InputsArray) =>{this.setState({LongDescriptionInputs:InputsArray})}

setItemEditSafariName  = (text) =>{this.setState({ItemEditSafariName:text.target.value})}
setItemEditShortDescription  = (text) =>{this.setState({ItemEditShortDescription:text.target.value})}


handleAdd = (currentInputs,inputsToSet) =>{
    // console.log("Adding..");
    const newInputs = [...currentInputs];
    newInputs.push('');

    if(inputsToSet === 'LongDescription'){this.setLongDescriptionTextInputs(newInputs)}
}
handleDelete = (currentInputs,inputsToSet) =>{
    // console.log("Deleting..");
    const newInputs = [...currentInputs];
    newInputs.pop();
    if(inputsToSet === 'LongDescription'){this.setLongDescriptionTextInputs(newInputs)}
}
handleTextInputChange = (text,index,currentInputs,inputsToSet) =>{
    // console.log("Handling Changes..");
    // console.log(".."+JSON.stringify(text));
    const newInputs = [...currentInputs];
    newInputs[index]={data:text.target.value};
    if(inputsToSet === 'LongDescription'){this.setLongDescriptionTextInputs(newInputs)}
}


handleImageUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) 
        {
            this.setState({SafariImage:file})
            const reader = new FileReader();
            
            reader.onloadend = () => {
            this.setState({SelectedImage:reader.result}); // Set the base64 image data to state
            };
            reader.readAsDataURL(file); // Convert image to base64 string
        }
};

resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
    this.setState({ShowPostButton:true});
    this.setState({ItemShowImage:null});

}
postSafari =  async () => 
    {
        let safariName = this.state.SafariName;
        let file = this.state.SafariImage;
        let shortDescription = this.state.ShortDescription;
        let longDescription = JSON.stringify(this.state.LongDescriptionInputs);

        if (safariName.length === 0 || file.length === 0  )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('name', safariName);
            formData.append('description', shortDescription);
            formData.append('longDescription', longDescription);
        
            try {
                    const response = await axios.post(APIBlogCreate, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadData();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
updateSafariItemText =  async () => {
    let updateId = this.state.updateId;
    let safariName = this.state.ItemEditSafariName;
    let shortDescription = this.state.ItemEditShortDescription;
    let longDescription = JSON.stringify(this.state.ItemEditLongDescription);


    if (safariName.length === 0 )
    {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
    else
    {
        const formData = new FormData();
        formData.append('updateId', updateId);
        formData.append('name', safariName);
        formData.append('description', shortDescription);
        formData.append('longDescription', longDescription);
    
        try {
                const response = await axios.put(APIBlogUpdateText, formData, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                this.loadData();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
        }
}
updateSafariItemImage =  async () => 
    {
        let file = this.state.SafariImage;
        let fileNameToDelete = this.state.FileNameToDelete
        let updateId = this.state.updateId;

        if (file.length === 0  )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('fileNameToDelete',fileNameToDelete)
            formData.append('updateId', updateId);
        
            try {
                    const response = await axios.put(APIBlogUpdateImage, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadData();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }

deleteSafariItem =  async () => 
    {
        let deleteId = this.state.deleteId
        try {
                const response = await axios.delete(APIBlogDelete+deleteId, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                this.loadData();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
    }
render() {
    const {show,ShowDetailsModel,ShowDeleteModel,ShowEditTextModel,ShowEditImageModel,showAlert,BlogData,SelectedImage,
            LongDescriptionInputs
    } =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Blogs Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New Blog
                                    </Button>
                                </ButtonGroup>
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    <p>{this.state.AllInputsRequiredError}</p>
                                    <p>{this.state.AxiosErrorMessage}</p>
                                    <p>{this.state.AxiosErrorResponse}</p>
                                </div>
                            </>):(<></>)}
                        <div className="row">
                            {showAlert ?(<> 
                                    <Alert variant="success" onClose={() => this.setState({showAlert: false})} dismissible>
                                        <Alert.Heading>Success </Alert.Heading>
                                        <p>{this.state.postMsg}</p>
                                    </Alert>
                                </>) :(<> </>)}
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Title Name</th>
                                            <th className="tr-td-labels text-center">Details</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {BlogData && BlogData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.name}</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.name,item.description,item.longDescription,item.fileName)}>
                                                    Show Item
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditTextModel(item.name,item.description,item.longDescription,item.id)}>
                                                    Edit Text
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditImageModel(item.id,item.fileName)}>
                                                    Edit Image
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="danger" onClick={()=>this.handleShowDeleteModel(item.name,item.id,item.fileName)}>
                                                    Delete Item
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>


                            <Modal size='lg'  show={show} onHide={()=>this.handleClose()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adding An New Blog</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Image</p>
                                                <input type="file" accept="image/*" className="form-control form-inputs" onChange={e=>this.handleImageUpload(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <center>
                                        {SelectedImage && (
                                            <div>
                                                <br></br><br></br>
                                                <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                            </div>
                                        )}
                                    </center>
                                    <br></br>
                                    <div>
                                        <label className="primary-text" ><b>Blog Title Name</b></label>
                                        <input  onChange={text=>this.setSafariName (text)} className="form-control form-inputs"/>
                                    </div>
                                    <br></br>
                                    <label className="primary-text"><b>Short Description *</b></label>
                                    <textarea type="text"  className="form-control form-inputs" placeholder="...."
                                        onChange={text => this.setShortDescriptionTextInputs (text)}
                                        />
                                        <br/><br/>

                                        <label className="primary-text"><b>Long Description *</b></label>
                                        {LongDescriptionInputs.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                value={item.text}
                                                onChange={text => this.handleTextInputChange(text,index,this.state.LongDescriptionInputs,'LongDescription')}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <center>
                                            <button type="button" className='btn btn-primary' onClick={()=>this.handleAdd(this.state.LongDescriptionInputs,'LongDescription')}>
                                                Add New Input
                                            </button>
                                            &nbsp;&nbsp;&nbsp;
                                            <button type="button" className='btn btn-danger' onClick={()=>this.handleDelete(this.state.LongDescriptionInputs,'LongDescription')}>
                                                Remove Input
                                            </button>
                                        </center>
                                        <br></br>



                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.postSafari()}>
                                    Save Changes
                                </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>

                            {/* Details Model   */}
                            <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Blog Item Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <center>
                                        <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                    </center>
                                    <div>
                                        <p className="primary-text" >
                                            <b>Blog Title Name</b><br></br>
                                            {this.state.ItemShowBlogName}
                                        </p>
                                    </div>

                                    <div>
                                        <p className="primary-text" >
                                            <b>Description </b><br></br>
                                            {this.state.ItemShowShortDescription}
                                        </p>
                                    </div>

                                    <div>
                                        <label className="primary-text"><b>Long Description</b></label>
                                        {this.state.ItemShowLongDescription && this.state.ItemShowLongDescription.map((item,index)=>(
                                            <div key={index}>
                                                <div >
                                                    <p >{item.data}</p>
                                                </div>
                                                
                                            </div>
                                        ))}
                                    </div>

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                             {/* Edit Text Model   */}
                            <Modal size='lg' show={ShowEditTextModel} onHide={()=>this.handleCloseEditTextModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Blog Item</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <label className="primary-text" ><b> Title Name</b></label>
                                    <input value={this.state.ItemEditSafariName} onChange={text=>this.setItemEditSafariName (text)} className="form-control form-inputs"/>
                                        
                                    <br></br>
                                    <label className="primary-text"><b>Short Description *</b></label>
                                    <textarea type="text"  className="form-control form-inputs" placeholder="...."
                                        onChange={text => this.setItemEditShortDescription (text)}
                                        value={this.state.ItemEditShortDescription}
                                        />
                                        <br/><br/>

                                        <label className="primary-text"><b>Long Description *</b></label>
                                        {this.state.ItemEditLongDescription.map((item,index)=>(
                                            <div key={index}>
                                                <input type="text"  className="form-control form-inputs" placeholder="Copy & Paste Paragraph.."
                                                defaultValue={item.data}
                                                onChange={(data) => {
                                                    const clonedItems = [...this.state.ItemEditLongDescription]; // Shallow copy
                                                    clonedItems[index].data = data.target.value;
                                                    this.setState({ ItemEditLongDescription: clonedItems });
                                                }}
                                                />
                                                <br/>
                                            </div>
                                        ))}
                                        <br></br>

                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                            </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                        </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditTextModel()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.updateSafariItemText()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                             {/* Edit Image Model   */}
                            <Modal show={ShowEditImageModel} onHide={()=>this.handleCloseEditImageModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Blog Item Image</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <div className='my-grid-container-2-columns' >
                                            <div className='my-grid-item'>
                                                <div>
                                                    
                                                    <p className="primary-text" >Image</p>
                                                    <input type="file" accept="image/*" className="form-control form-inputs" onChange={e=>this.handleImageUpload(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <center>
                                            <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                            
                                            {SelectedImage && (
                                                <div>
                                                    <br></br><br></br>
                                                    <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                </div>
                                            )}
                                        </center>
                                        <br></br>
                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                            </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                        </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditImageModel()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.updateSafariItemImage()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>

                            {/* Delete Model   */}
                            <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Delete Blog Item</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Delete This Blog Item ??</p>
                                    </div>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.ItemShowBlogName}
                                                </p>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                        </div>

                                    </div>
                                    <center>
                                        <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                    </center>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.deleteSafariItem()}>
                                        Yes Delete Item
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}

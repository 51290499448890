
import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import LogIn from './Components/Auth/LogIn';
import Dashboard from './Components/Pages/Dashboard';

// Package
import Package from './Components/Pages/Package/Package';
import PackageReservation from './Components/Pages/Package/Reservation';
import PackageBooking from './Components/Pages/Package/Booking';

// profile
import Profile from './Components/Pages/Navigation/Profile';

// Safari
import Safari from './Components/Pages/Safari/Safari';
import Blogs from './Components/Pages/Others/Blogs';

// Users 
import AppUsers from './Components/Pages/Others/AppUsers';
import SliderImages from './Components/Pages/Others/SliderImages';
import Destination from './Components/Pages/Safari/Destination';
import Hotels from './Components/Pages/Others/Hotels';
import Transport from './Components/Pages/Transport/Transport';
import TransportBooking from './Components/Pages/Transport/TransportBooking';
function App() {

	return (
        <Router >
			<Routes>
				<Route path="/" element={<LogIn/>} />

				<Route path="/dashboard" element={<Dashboard/>} />
				<Route path="/package" element={<Package/>} />
				<Route path="/packageReservation" element={<PackageReservation/>} />
				<Route path="/packageBooking" element={<PackageBooking/>} />

				<Route path="/profile" element={<Profile/>} />

				<Route path="/safari" element={<Safari/>} />
				<Route path="/blogs" element={<Blogs/>} />

				<Route path="/appUsers" element={<AppUsers/>} />
				<Route path="/sliderImage" element={<SliderImages/>} />
				
				<Route path="/destinations" element={<Destination/>} />
				<Route path="/hotels" element={<Hotels/>} />
				<Route path="/transport" element={<Transport/> } />
				<Route path="/transportBooking" element={<TransportBooking/> } />

			</Routes>
        </Router>
		
);}

export default App;

import { APP_AUTH_TOKEN, APP_URL } from "./Env";

const BaseUrl = APP_URL; // Set App url
const Auth_Token = APP_AUTH_TOKEN; // set App Auth Token
export const AUTH_TOKEN = Auth_Token;

// User 
export const APIUserCreate = BaseUrl+"/api/isoke/user/create";
export const APIUserListByEmail = BaseUrl+"/api/isoke/user/list/by/email/";
export const APIUsersList = BaseUrl+"/api/isoke/user/list/all";
export const APIUserUpdate = BaseUrl+"/api/isoke/user/update";
export const APIUserRoleUpdate = BaseUrl+"/api/isoke/user/role/update";
export const APIUserUpdatePassword = BaseUrl+"/api/isoke/user/password/update";

// Package
export const APIPackageCreate = BaseUrl+"/api/isoke/package/create";
export const APIPackageList = BaseUrl+"/api/isoke/package/list/all";
export const APIPackageFileName = BaseUrl+"/api/isoke/package/image/file/";
export const APIPackageUpdateText = BaseUrl+"/api/isoke/package/item/text/update";
export const APIPackageUpdateImage = BaseUrl+"/api/isoke/package/item/image/update";
export const APIPackageDelete = BaseUrl+"/api/isoke/package/item/delete/";

// Package Booking
export const APIPackageBookingList = BaseUrl+"/api/isoke/package/booking/list/all";
export const APIPackageBookingDelete = BaseUrl+"/api/isoke/package/booking/item/delete/";

// Package Reservation
export const APIPackageReservationList = BaseUrl+"/api/isoke/package/reservation/list/all";
export const APIPackageReservationDelete = BaseUrl+"/api/isoke/package/reservation/item/delete/";


// Safari
export const APISafariCreate = BaseUrl+"/api/isoke/safari/create";
export const APISafariList = BaseUrl+"/api/isoke/safari/list/all";
export const APISafariFileName = BaseUrl+"/api/isoke/safari/image/file/";
export const APISafariUpdateText = BaseUrl+"/api/isoke/safari/item/text/update";
export const APISafariUpdateImage = BaseUrl+"/api/isoke/safari/item/image/update";
export const APISafariDelete = BaseUrl+"/api/isoke/safari/item/delete/";

// Blog
export const APIBlogCreate = BaseUrl+"/api/isoke/blog/create";
export const APIBlogList = BaseUrl+"/api/isoke/blog/list/all";
export const APIBlogFileName = BaseUrl+"/api/isoke/blog/image/file/";
export const APIBlogUpdateText = BaseUrl+"/api/isoke/blog/item/text/update";
export const APIBlogUpdateImage = BaseUrl+"/api/isoke/blog/item/image/update";
export const APIBlogDelete = BaseUrl+"/api/isoke/blog/item/delete/";

// Destination
export const APIDestinationCreate = BaseUrl+"/api/isoke/destination/create";
export const APIDestinationList = BaseUrl+"/api/isoke/destination/list/all";
export const APIDestinationFileName = BaseUrl+"/api/isoke/destination/image/file/";
export const APIDestinationUpdateText = BaseUrl+"/api/isoke/destination/item/text/update";
export const APIDestinationUpdateImage = BaseUrl+"/api/isoke/destination/item/image/update";
export const APIDestinationDelete = BaseUrl+"/api/isoke/destination/item/delete/";

// Hotel
export const APIHotelCreate = BaseUrl+"/api/isoke/hotel/create";
export const APIHotelList = BaseUrl+"/api/isoke/hotel/list/all";
export const APIHotelFileName = BaseUrl+"/api/isoke/hotel/image/file/";
export const APIHotelUpdateText = BaseUrl+"/api/isoke/hotel/item/text/update";
export const APIHotelUpdateImage = BaseUrl+"/api/isoke/hotel/item/image/update";
export const APIHotelDelete = BaseUrl+"/api/isoke/hotel/item/delete/";


// Transport Booking
export const APITransportBookingList = BaseUrl+"/api/isoke/transport/booking/list/all";
export const APITransportBookingDelete = BaseUrl+"/api/isoke/transport/booking/item/delete/";

// Transport
export const APITransportCreate = BaseUrl+"/api/isoke/transport/create";
export const APITransportList = BaseUrl+"/api/isoke/transport/list/all";
export const APITransportFileName = BaseUrl+"/api/isoke/transport/image/file/";
export const APITransportUpdateText = BaseUrl+"/api/isoke/transport/item/text/update";
export const APITransportUpdateImage = BaseUrl+"/api/isoke/transport/item/image/update";
export const APITransportDelete = BaseUrl+"/api/isoke/transport/item/delete/";

// Slider 
export const APISliderCreate = BaseUrl+"/api/isoke/slider/create";
export const APISliderList = BaseUrl+"/api/isoke/slider/list/all";
export const APISliderFileName = BaseUrl+"/api/isoke/slider/image/file/";
export const APISliderUpdateText = BaseUrl+"/api/isoke/slider/item/text/update";
export const APISliderUpdateImage = BaseUrl+"/api/isoke/slider/item/image/update";
export const APISliderDelete = BaseUrl+"/api/isoke/slider/item/delete/";

// Contact Us
export const APIContactUsList = BaseUrl+"/api/isoke/contact/us/list/all";
export const APIContactUsDelete = BaseUrl+"/api/isoke/contact/us/item/delete/";
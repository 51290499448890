
export const PackagesNavigationLinks = [ 
    {id:1, name:"Packages", path:"/package"},
    {id:2, name:"Bookings", path:"/packageBooking"},
    {id:3, name:"Reservations", path:"/packageReservation"},
]

export const SafariNavigationLinks = [ 
    {id:1, name:"Safari", path:"/safari"},
    {id:2, name:"Blogs", path:"/blogs"},
    {id:3, name:"Destinations", path:"/destinations"},
]


export const TransportNavigationLinks = [ 
    {id:1, name:"Transport", path:"/transport"},
    {id:2, name:"Booking", path:"/transportBooking"},
]

export const OthersNavigationLinks = [ 
    {id:1, name:"Slider", path:"/sliderImage"},
    {id:2, name:"Hotels", path:"/hotels"},
    {id:3, name:"Users", path:"/appUsers"},
]


export const DestinationNamesList = [ 
    {id:1, code:"UG", name:"Uganda", path:"/destination"},
    {id:2, code:"KE", name:"Kenya", path:"/destination"},
    {id:3, code:"TA", name:"Tanzania", path:"/destination"},
    {id:4, code:"RW", name:"Rwanda", path:"/destination"},
    {id:5, code:"DR", name:"DRC", path:"/destination"},
]


export const TourNamesList = [ 
    {id:1, code:"CA", name:"Camping", path:"/safariTours"},
    {id:2, code:"NA", name:"Nature" , path:"/safariTours"},
    {id:3, code:"HI", name:"Hinking", path:"/safariTours"},
    {id:4, code:"SP", name:"Sports", path:"/safariTours"},
    {id:5, code:"WI", name:"Wild Life", path:"/safariTours"},
    {id:6, code:"HO", name:"Honey moon", path:"/safariTours"},
]

export const TransportTypeList = [ 
    {id:1, code:"4X4", name:"4 X 4 Cars", path:"/transport"},
    {id:2, code:"DR", name:"Drone Cars", path:"/transport"},
    {id:3, code:"BA", name:"Balloon Transport", path:"/transport"},
]
export const TourDaysList = [ 
    {id:1, code:"5D4N", name:"5 Days And 4 Nights"},
    {id:2, code:"4D3N", name:"4 Days And 3 Nights"},
    {id:3, code:"3D2N", name:"3 Days And 2 Nights"},
    {id:4, code:"2D1N", name:"2 Days And 1 Night"},
    {id:5, code:"2D1N", name:"2 Days And 1 Night"},
]
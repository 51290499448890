

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import { APISliderDelete, APISliderFileName, APISliderList, APISliderUpdateImage, APISliderCreate, AUTH_TOKEN, } from '../../Utilities/APIS';
import { ERROR_POST, INPUTS_REQUIRED } from '../../Utilities/Errors';
import { APISliderUpdateText } from '../../Utilities/APIS';

const headers = {'Content-Type': 'multipart/form-data','Authorization': `Bearer ${AUTH_TOKEN}`};

export default class SliderImages extends React.Component {
    
constructor(props){
    
    super(props);
    this.state = {
        
        ShowPostButton:true,
        showAlert:false,
        SlidersData:[],

        ItemTitle:'',
        ItemDescription:'',
        ItemImage:null,
        SelectedImage:null,

        SuccessPostMsg:'',
        ErrorPostMsg:'',

        ShowAlertSuccessPostMsg:false,
        ShowAlertErrorPostMsg:false,
        ShowAlertAllInputsRequired:false,

        // models 
        show:false,
        ShowDetailsModel:false,
        ShowEditTextModel:false,
        ShowEditImageModel:false,
        ShowDeleteModel:false,

        ItemShowName:'',
        ItemShowDescription:'',
        ItemShowImage:'',
        updateId:'',
        deleteId:'',
        deleteFileName:'',
        ItemShowDescription:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',
        AllInputsRequiredError:'',


        
    }    
}

componentDidMount(){
    this.loadSliders();
}

loadSliders  = () =>{

    axios.get(APISliderList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({SlidersData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false}); this.resetModelShowAlerts();}
handleShowDetailsModel = (name,price,description,image) => {
    this.setState({ItemShowName:name});
    this.setState({ItemShowDescription:price});
    this.setState({ItemShowDescription:description});
    this.setState({ItemShowImage:APISliderFileName+image});
    this.setState({ShowDetailsModel:true});
}

handleCloseEditTextModel = () => {this.setState({ShowEditTextModel:false});this.resetModelShowAlerts();}
handleShowEditTextModel = (title,description,updateId,image) => {
    this.setState({ItemTitle:title});
    this.setState({ItemDescription:description});
    this.setState({ItemImage:image});
    this.setState({updateId:updateId})
    this.setState({ItemShowImage:APISliderFileName+image});
    this.setState({ShowEditTextModel:true});
    }
handleCloseEditImageModel = () => {this.setState({ShowEditImageModel:false});this.resetModelShowAlerts();}
handleShowEditImageModel = (updateId,image) => {
        this.setState({ItemImage:image});
        this.setState({updateId:updateId})
        this.setState({ItemShowImage:APISliderFileName+image});
        this.setState({ShowEditImageModel:true});
        }
handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (title,description,deleteId,deleteFileName) => {
    this.setState({ItemShowName:title});
    this.setState({ItemShowDescription:description});
    this.setState({deleteId:deleteId}); 
    this.setState({deleteFileName:deleteFileName}); 
    this.setState({ItemShowImage:APISliderFileName+deleteFileName});
    this.setState({ShowDeleteModel:true});
}

setItemTitle = (text) =>{this.setState({ItemTitle:text.target.value})}
setItemDescription = (text) =>{this.setState({ItemDescription:text.target.value})}



handleImageUpload = (e) => {
    const file = e.target.files[0]; 
    if (file) 
        {
            this.setState({ItemImage:file})
            const reader = new FileReader();
            
            reader.onloadend = () => {
            this.setState({SelectedImage:reader.result}); // Set the base64 image data to state
            };
            reader.readAsDataURL(file); // Convert image to base64 string
        }
};

resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
    this.setState({ItemShowImage:null});
    this.setState({ShowPostButton:true});

}
postSlider =  async () => 
    {
        let title = this.state.ItemTitle;
        let description = this.state.ItemDescription;
        let file = this.state.ItemImage;

        if (title.length === 0 || description.length === 0 || file.length === 0 )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('title', title);
            formData.append('description', description);
        
            try {
                    const response = await axios.post(APISliderCreate, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadSliders();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
updateSliderText =  async () => 
    {
        let title = this.state.ItemTitle;
        let description = this.state.ItemDescription;
        let updateId = this.state.updateId;

        if (title.length === 0 || description.length === 0)
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('updateId', updateId);
        
            try {
                    const response = await axios.put(APISliderUpdateText, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadSliders();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }
updateSliderImage =  async () => 
    {
        let file = this.state.ItemImage;
        let updateId = this.state.updateId;

        if (file.length === 0  )
        {this.setState({ShowAlertAllInputsRequired:true});this.setState({AllInputsRequiredError:INPUTS_REQUIRED});}
        else
        {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('updateId', updateId);
        
            try {
                    const response = await axios.put(APISliderUpdateImage, formData, {headers});
                    this.setState({ShowAlertSuccessPostMsg:true});
                    this.setState({ShowAlertErrorPostMsg:false});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({SuccessPostMsg:response.data});
                    this.setState({ShowPostButton:false});                   
                    this.loadSliders();
                } 
                catch (error) {
                    this.setState({ShowAlertErrorPostMsg:true});
                    this.setState({ShowAlertAllInputsRequired:false});
                    this.setState({ShowAlertSuccessPostMsg:false});
                    this.setState({ErrorPostMsg:ERROR_POST+error.message})
                    }
            }
    }

deleteSlider =  async () => 
    {
        let deleteId = this.state.deleteId
        try {
                const response = await axios.delete(APISliderDelete+deleteId, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                this.loadSliders();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
    }
render() {
    const {show,ShowDetailsModel,ShowDeleteModel,ShowEditTextModel,ShowEditImageModel,showAlert,SlidersData,SelectedImage,} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Slider  Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New Slider
                                    </Button>
                                </ButtonGroup>
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                <p>{this.state.AllInputsRequiredError}</p>
                                <p>{this.state.AxiosErrorMessage}</p>
                                <p>{this.state.AxiosErrorResponse}</p>
                            </div>
                        </>):(<></>)}
                        <div className="row">
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Title</th>
                                            <th className="tr-td-labels text-center">Description</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Modify</th>
                                            <th className="tr-td-labels text-center">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {SlidersData && SlidersData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.title}</td>
                                            <td className="tr-td-labels text-center">{item.description}</td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.title,item.description,item.fileName)}>
                                                    Show Item
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditTextModel(item.title,item.description,item.id)}>
                                                    Edit Text
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="success" onClick={()=>this.handleShowEditImageModel(item.id,item.fileName)}>
                                                    Edit Image
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="danger" onClick={()=>this.handleShowDeleteModel(item.title,item.description,item.id,item.fileName)}>
                                                    Delete Item
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>


                            <Modal show={show} onHide={()=>this.handleClose()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Adding An New Slider</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Title</p>
                                                <input type="text" onChange={text=>this.setItemTitle (text)} className="form-control form-inputs" autoComplete="off" placeholder="Title"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >Description</p>
                                                <input type="text" onChange={text=>this.setItemDescription (text)} className="form-control form-inputs" autoComplete="off" placeholder="Description"/>
                                            </div>
                                        </div>
                                        <div className='my-grid-item'>
                                            <div>
                                                
                                                <p className="primary-text" >Image</p>
                                                <input type="file" accept="image/*" className="form-control form-inputs" onChange={e=>this.handleImageUpload(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <center>
                                        {SelectedImage && (
                                            <div>
                                                <br></br><br></br>
                                                <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                            </div>
                                        )}
                                    </center>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}

                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleClose()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.postSlider()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>

                            {/* Details Model   */}
                            <Modal show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Slider Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <div>
                                            <p className="primary-text" >
                                                <b>Title</b><br></br>
                                                {this.state.ItemShowName}
                                            </p>
                                        </div>
                                        <div>
                                            <p className="primary-text" >
                                                <b>Description</b><br></br>
                                                {this.state.ItemShowDescription}
                                            </p>
                                        </div>
                                        <center>
                                            <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                        </center>
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                             {/* Edit Text Model   */}
                            <Modal show={ShowEditTextModel} onHide={()=>this.handleCloseEditTextModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Slider</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <p className="primary-text" >Title</p>
                                            <input type="text" value={this.state.ItemTitle} onChange={text=>this.setItemTitle (text)} className="form-control form-inputs" autoComplete="off" placeholder="Title"/>
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text" >Description</p>
                                            <input type="text" value={this.state.ItemDescription} onChange={text=>this.setItemDescription (text)} className="form-control form-inputs" autoComplete="off" placeholder="Description"/>
                                        </div>
                                    </div>
                                        
                                        <br></br>
                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                            </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                        </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditTextModel()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.updateSliderText()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                             {/* Edit Image Model   */}
                            <Modal show={ShowEditImageModel} onHide={()=>this.handleCloseEditImageModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Slider Image</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                        <div className='my-grid-container-2-columns' >
                                            <div className='my-grid-item'>
                                                <div>
                                                    
                                                    <p className="primary-text" >Image</p>
                                                    <input type="file" accept="image/*" className="form-control form-inputs" onChange={e=>this.handleImageUpload(e)} />
                                                </div>
                                            </div>
                                        </div>
                                        <center>
                                            <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                            
                                            {SelectedImage && (
                                                <div>
                                                    <br></br><br></br>
                                                    <img src={SelectedImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                                </div>
                                            )}
                                        </center>
                                        <br></br>
                                        {this.state.ShowAlertSuccessPostMsg ?(<>
                                            <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.SuccessPostMsg}
                                            </div>
                                        </>):(<></>)}
                                        {this.state.ShowAlertErrorPostMsg ?(<>
                                            <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                                {this.state.ErrorPostMsg}
                                            </div>
                                            </>):(<></>)}
                                        {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                        </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseEditImageModel()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.updateSliderImage()}>
                                        Save Changes
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>

                            {/* Delete Model   */}
                            <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Edit Slider</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Delete This Slider ??</p>
                                    </div>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <p className="primary-text" >
                                                <b>Title</b><br></br>
                                                {this.state.ItemShowName}
                                            </p>
                                        </div>
                                        <div className='my-grid-item'>
                                            <p className="primary-text" >
                                                <b>Description</b><br></br>
                                                {this.state.ItemShowDescription}
                                            </p>
                                        </div>

                                    </div>
                                    <center>
                                        <img src={this.state.ItemShowImage} alt="Uploaded" className="rounded-circle selected-upload-image" />
                                    </center>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.deleteSlider()}>
                                        Yes Delete Item
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}

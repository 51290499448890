

import React from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../Utilities/css/main.css';
import '../../Utilities/assets/bootstrap/css/bootstrap.min.css';
import '../../Utilities/assets/css/style.css';
import Header from '../Navigation/Header';
import {APIPackageReservationDelete, APIPackageReservationList,AUTH_TOKEN } from '../../Utilities/APIS';
import { ERROR_POST } from '../../Utilities/Errors';

const headers = {'Authorization': `Bearer ${AUTH_TOKEN}`};
export default class Reservation extends React.Component {
    
constructor(props){
    super(props);
    this.state = {
        ShowPostButton:true,
        showAlert:false,
        ReservationData:[],

        SuccessPostMsg:'',
        ErrorPostMsg:'',

        // models 
        show:false,
        ShowDetailsModel:false,
        deleteId:'',

        // Loading 
        ShowAxiosErrorAlert:false,
        AxiosErrorMessage:'',
        AxiosErrorResponse:'',

        // showing
        BookingDate:'',BookingTitle:'',CustomerName:'',CustomerEmail:'',
        CustomerPhone:'',CustomerCountry:'',CustomerNumberOfPeople:'',
        CustomerArrivalDate:'',CustomerOtherNotes:'',
        CustomerAccommodationRange:''
        
    }    
}

componentDidMount(){
    this.loadData();
}


loadData = () =>{
    axios.get(APIPackageReservationList,{headers})
    .then(res => {
        let results =JSON.stringify(res.data);
        let jsonResults =JSON.parse(results); 
        this.setState({ReservationData:jsonResults})
        })
    .catch(error=>{
            console.log(error);
            this.setState({AxiosErrorMessage:error.message});
            this.setState({AxiosErrorResponse:error.request.response});
            this.setState({ShowAxiosErrorAlert:true});
        })
}
// models
handleClose = () => {this.setState({show:false});this.resetModelShowAlerts();}
handleShow = () => {this.setState({show:true});}

handleCloseDetailsModel = () => {this.setState({ShowDetailsModel:false}); this.resetModelShowAlerts();}
handleShowDetailsModel = (bookingDate,bookingTitle,customerName,customerEmail,customerPhone,customerCountry,
    customerNumberOfPeople,customerArrivalDate,customerOtherNotes,customerAccommodationRange) => {
    this.setState({BookingDate:bookingDate});
    this.setState({BookingTitle:bookingTitle});
    this.setState({CustomerName:customerName});
    this.setState({CustomerEmail:customerEmail});
    this.setState({CustomerPhone:customerPhone});
    this.setState({CustomerCountry:customerCountry});
    this.setState({CustomerNumberOfPeople:customerNumberOfPeople});
    this.setState({CustomerArrivalDate:customerArrivalDate});
    this.setState({CustomerOtherNotes:customerOtherNotes});
    this.setState({CustomerAccommodationRange:customerAccommodationRange})

    this.setState({ShowDetailsModel:true});
}


handleCloseDeleteModel = () => {this.setState({ShowDeleteModel:false});this.resetModelShowAlerts();}
handleShowDeleteModel = (bookingTitle,deleteId) => {
    this.setState({BookingTitle:bookingTitle});
    this.setState({deleteId:deleteId}); 
    this.setState({ShowDeleteModel:true});
}



resetModelShowAlerts = () =>
{
    this.setState({ShowAlertSuccessPostMsg:false});
    this.setState({ShowAlertErrorPostMsg:false});
    this.setState({ShowAlertAllInputsRequired:false});
    this.setState({ShowPostButton:true});

}

deletePackageReservationItem =  async () => 
    {
        let deleteId = this.state.deleteId
        try {
                const response = await axios.delete(APIPackageReservationDelete+deleteId, {headers});
                this.setState({ShowAlertSuccessPostMsg:true});
                this.setState({ShowAlertErrorPostMsg:false});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({SuccessPostMsg:response.data});
                this.setState({ShowPostButton:false});                   
                this.loadData();
            } 
            catch (error) {
                this.setState({ShowAlertErrorPostMsg:true});
                this.setState({ShowAlertAllInputsRequired:false});
                this.setState({ShowAlertSuccessPostMsg:false});
                this.setState({ErrorPostMsg:ERROR_POST+error.message})
                }
    }
render() {
    const {ShowDetailsModel,ShowDeleteModel,ReservationData} =this.state;
return (
            <div  className='body-container'>
                <div className='nav-header-container'>
                    <Header/>
                </div>
                <div className='inner-body-container'>

                        <div className="alert alert-light bg-light text-light border-0 alert-dismissible" role="alert">
                            <strong className='strong-text primary-text' >Package Booking Listings</strong><span className='strong-text primary-text'>View</span>
                            <div className="custom-alert-btn-actions">
                                {/* <ButtonGroup className="mb-2">
                                    <Button variant="primary" onClick={()=>this.handleShow()}>
                                        Add New Package
                                    </Button>
                                </ButtonGroup> */}
                                </div>
                        </div><br/>
                        {this.state.ShowAxiosErrorAlert ?(<>
                                <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                    <p>{this.state.AllInputsRequiredError}</p>
                                    <p>{this.state.AxiosErrorMessage}</p>
                                    <p>{this.state.AxiosErrorResponse}</p>
                                </div>
                            </>):(<></>)}
                        <div className="row">
                            <div className="col-12">
                                <div className="card recent-sales overflow-auto">
                                    <div className="card-body">
                                    <table className="table table-hover"  id="table">
                                    <thead>
                                        <tr>
                                            <th className="tr-td-labels text-center"> SR.No</th>
                                            <th className="tr-td-labels text-center">Customer</th>
                                            <th className="tr-td-labels text-center">Date</th>
                                            <th className="tr-td-labels text-center">Package</th>
                                            <th className="tr-td-labels text-center">Details</th>
                                            <th className="tr-td-labels text-center">Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ReservationData && ReservationData.map((item,index)=>(
                                            <tr key={index}>
                                            <td className="tr-td-labels text-center">{index+1}</td>
                                            <td className="tr-td-labels text-center">{item.customerName}</td>
                                            <td className="tr-td-labels text-center">{item.bookingDate}</td>
                                            <td className="tr-td-labels text-center">{item.bookingTitle}</td>

                                            <td className="tr-td-labels text-center">
                                                <Button variant="dark" onClick={()=>this.handleShowDetailsModel(item.bookingDate,item.bookingTitle,item.customerName,item.customerEmail,
                                                    item.customerPhone,item.customerCountry,item.customerNumberOfPeople,item.customerArrivalDate,item.customerOtherNotes,
                                                    item.customerAccommodationRange
                                                )}>
                                                    Show Item
                                                </Button>
                                            </td>
                                            <td className="tr-td-labels text-center">
                                                <Button variant="danger" onClick={()=>this.handleShowDeleteModel(item.bookingTitle,item.id)}>
                                                    Delete Item
                                                </Button>
                                            </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                    </div>
                                </div>
                            </div>


                            {/* Details Model   */}
                            <Modal size='lg' show={ShowDetailsModel} onHide={()=>this.handleCloseDetailsModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                <Modal.Title>Package Booking Details</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='my-grid-container-4-columns'>
                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Customer Name</b><br></br>
                                                {this.state.CustomerName}
                                            </p>
                                        </div>

                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Customer Email</b><br></br>
                                                {this.state.CustomerEmail}
                                            </p>
                                        </div>
                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Booking Date</b><br></br>
                                                {this.state.BookingDate}
                                            </p>
                                        </div>
                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Customer Phone</b><br></br>
                                                {this.state.CustomerPhone}
                                            </p>
                                        </div>
                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Customer Country</b><br></br>
                                                {this.state.CustomerCountry}
                                            </p>
                                        </div>

                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Number Of People</b><br></br>
                                                {this.state.CustomerNumberOfPeople}
                                            </p>
                                        </div>

                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Arrival Date</b><br></br>
                                                {this.state.CustomerArrivalDate}
                                            </p>
                                        </div>
                                        <div className='my-grid-item' >
                                            <p className="primary-text" >
                                                <b>Class Range</b><br></br>
                                                {this.state.CustomerAccommodationRange}
                                            </p>
                                        </div>
                                    </div>
                                    

                                    <div >
                                        <p className="primary-text" >
                                            <b>Booking Title</b><br></br>
                                            {this.state.BookingTitle}
                                        </p>
                                    </div>

                                    <div >
                                        <p className="primary-text" >
                                            <b>Other Notes</b><br></br>
                                            {this.state.CustomerOtherNotes}
                                        </p>
                                    </div>
                                    
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDetailsModel()}>
                                    Close
                                </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Delete Model   */}
                            <Modal show={ShowDeleteModel} onHide={()=>this.handleCloseDeleteModel ()} backdrop="static" keyboard={false}>
                                <Modal.Header>
                                    <Modal.Title>Delete Package Booking</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                        <p>Are You Sure You Want to Delete This Package Booking ??</p>
                                    </div>
                                    <div className='my-grid-container-2-columns' >
                                        <div className='my-grid-item'>
                                            <div>
                                                <p className="primary-text" >
                                                    <b>Name</b><br></br>
                                                    {this.state.CustomerName}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    {this.state.ShowAlertSuccessPostMsg ?(<>
                                        <div className="alert alert-success bg-success text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.SuccessPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertErrorPostMsg ?(<>
                                        <div className="alert alert-danger bg-danger text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.ErrorPostMsg}
                                        </div>
                                    </>):(<></>)}
                                    {this.state.ShowAlertAllInputsRequired ?(<>
                                        <div className="alert alert-dark bg-dark text-light border-0 alert-dismissible fade show" role="alert">
                                            {this.state.AllInputsRequiredError}
                                        </div>
                                    </>):(<></>)}
                                </Modal.Body>
                                <Modal.Footer>
                                <Button variant="danger" onClick={()=>this.handleCloseDeleteModel ()}>
                                    Close
                                </Button>
                                {this.state.ShowPostButton?(<>
                                    <Button variant="primary" onClick={()=>this.deletePackageReservationItem()}>
                                        Yes Delete Item
                                    </Button>
                                </>):(<></>)}
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </div>
                    {/* End col */}
            </div>
);
}}
